//------------------------------------------------------------------------------
// VARIABLEN

$brand-color-1: #1269b0;
$light-border-color: #bfbfbf;
$dark-border-color: #818181;
$bg-color-1: #e6e6e6;
$text-color: #333;
$light-text-color: #fff;
$warning-color: #FF2800;
$okay-color: #1C9400;

$font-family-bold: "lato-bold", sans-serif;
$font-family-regular: "lato-regular", sans-serif;

$breakpoint-sm: 480px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-xl: 1200px;

$container-max: 920px;


// -----------------------------------------------------------------------------
// IMPORTS

@import "grid";


// -----------------------------------------------------------------------------
// HELPERS

.x-hidden {
  display: none !important;
}


// -----------------------------------------------------------------------------
// FORM

.x-form {
  &-event-registration {
    margin-bottom: 5rem;
  }

  &-item {
    margin-bottom: 1.4rem;
  }

  &-label {
    display: block;
    color: $text-color;
    font-family: $font-family-regular;
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }

  &-input,
  &-textarea {
    border: 1px solid $dark-border-color;
    border-radius: 0;
    color: $text-color;
    font-size: 14px;
    outline: 0;
    padding: 5px;
    width: 100%;

    &:focus {
      border-color: $brand-color-1;
      box-shadow: 0 0 0 3px rgba($brand-color-1, 0.24);
    }

    &[readonly] {
      background: rgba($light-border-color, 0.4);
      cursor: default;
    }
  }

  &-input {
    height: 34px;
    line-height: 1;
  }

  &-textarea {
    line-height: 1.5;
    resize: vertical;
  }

  &-checkbox {
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: -1;

    &-label {
      display: flex;
      font-weight: 400;
      margin: 5px 0 0 0;
      position: relative;
    }

    &-indicator {
      border: 1px solid $dark-border-color;
      border-radius: 3px;
      cursor: pointer;
      display: block;
      flex: 0 0 18px;
      height: 18px;
      max-width: 18px;
      position: relative;
      top: 2px;
      width: 18px;
    }

    &-text {
      cursor: pointer;
      margin: 0 10px 0 5px;
    }

    &:checked + .x-form-checkbox-indicator {
      &:after {
        background: $brand-color-1;
        border-radius: 3px;
        content: '';
        height: 10px;
        left: 3px;
        position: absolute;
        top: 3px;
        width: 10px;
      }
    }

    &:focus + .x-form-checkbox-indicator {
      border-color: $brand-color-1;
      box-shadow: 0 0 0 3px rgba($brand-color-1, 0.24);
    }
  }


  &-radio {
    opacity: 0;
    position: absolute;
    top: 0;
    z-index: -1;

    &-wrapper {
      display: flex;
    }

    &-legend {
      border: 0;
      color: $text-color;
      font-family: $font-family-regular;
      font-size: 14px;
      line-height: 1.5;
      font-weight: 600;
      margin: 0;
    }

    &-label {
      display: flex;
      font-weight: 400;
      margin: 5px 0 0 0;
      position: relative;
    }

    &-indicator {
      border: 1px solid $dark-border-color;
      border-radius: 50%;
      cursor: pointer;
      display: block;
      flex: 0 0 18px;
      height: 18px;
      max-width: 18px;
      position: relative;
      top: 2px;
      width: 18px;

      &:after {
        border-radius: 50%;
        content: '';
        height: 10px;
        left: 3px;
        position: absolute;
        top: 3px;
        width: 10px;
      }
    }

    &-text {
      cursor: pointer;
      margin: 0 10px 0 5px;
    }

    &:checked + .x-form-radio-indicator {
      &:after {
        background: $brand-color-1;
      }
    }

    &:focus + .x-form-radio-indicator {
      border-color: $brand-color-1;
      box-shadow: 0 0 0 3px rgba($brand-color-1, 0.24);
    }

    &[readonly] + .x-form-radio-indicator {
      cursor: not-allowed;

      &:after {
        background: transparent;
      }
    }
  }

  &-warning {
    background: $warning-color;
    color: $light-text-color;
    display: inline-block;
    margin-right: 4px;
    padding: 0 5px;
  }

  &-required {
    border: 0 !important;
    color: $warning-color;
    cursor: help;
    text-decoration: none;
  }

  &-button {
    background: $brand-color-1;
    border: 0;
    color: $light-text-color;
    border-radius: 0;
    display: block;
    height: 40px;
    line-height: 40px;
    margin-top: 30px;
    outline: 0;
    padding: 0 18px;
    text-transform: uppercase;
    width: 100%;

    &-event-registration {
      @media (min-width: $breakpoint-md) {
        margin-left: auto
      }
    }

    @media (min-width: $breakpoint-md) {
      width: auto;
    }

    &:focus {
      box-shadow: 0 0 0 3px rgba($brand-color-1, 0.24);
    }
  }
}


// -----------------------------------------------------------------------------
// CKEDITOR

.cke_button__custom_placeholder_icon {
  background-size: auto;
}


// -----------------------------------------------------------------------------
// REGISTRATION: PREVIEW

.x-event-preview {
  background: $warning-color;
  color: $light-text-color;
  font-size: 5rem;
  margin: 0;
  padding: 4rem 1rem;
  text-align: center;
  text-transform: uppercase;
}


// -----------------------------------------------------------------------------
// REGISTRATION: HEADER

.x-event-header {
  background: $brand-color-1;
  padding: 0.8rem 0;

  @media (min-width: $breakpoint-lg) {
    background: none;
  }

  &-logo-mobile {
    @media (min-width: $breakpoint-lg) {
      display: none;
    }
  }

  &-logo {
    display: none;

    @media (min-width: $breakpoint-lg) {
      display: block;
    }
  }

  &-image {
    display: none;

    @media (min-width: $breakpoint-lg) {
      border-bottom: 1rem solid $brand-color-1;
      display: block;
      height: auto;
      margin: 0 auto;
      max-width: 192rem;
      width: 100%;
    }

    &-newsletter {
      border-bottom: none;
    }
  }
}


// -----------------------------------------------------------------------------
// REGISTRATION: FOOTER

.x-event-footer {
  margin: 0 auto;
  max-width: 192rem;
}

// -----------------------------------------------------------------------------
// REGISTRATION

.x-event-message {
  margin-top: 2rem;
}

.x-group {
  &-title {
    align-items: center;
    color: $brand-color-1 !important;
    display: flex;
    font-family: $font-family-regular !important;
    font-size: 2.2rem !important;
    font-weight: 400;
    margin: 4rem 0 2.7rem 0 !important;
    justify-content: center;
    text-transform: uppercase;
  }

  &-highlighted {
    font-family: $font-family-bold;
    font-weight: 600;
  }

  &-icon {
    display: block;
    margin: 0 2rem 0 0;
  }
}

.x-separator {
  border: 0 !important;
  border-top: 0.1rem solid $dark-border-color !important;
  margin: 0 !important;
}


// -----------------------------------------------------------------------------
// REGISTRATION: HEADER

.x-header {
  &-title {
    color: $brand-color-1 !important;
    font-family: $font-family-bold !important;
    font-size: 3.4rem !important;
    font-weight: 600 !important;
    line-height: 1.1 !important;
    margin: 5.5rem 0 1.4rem 0 !important;
  }

  &-text {
    color: $text-color;
    font-family: $font-family-bold;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.6;
    margin: 0.5rem 0 1.4rem 0;
  }
}


// -----------------------------------------------------------------------------
// REGISTRATION: PERSONAL DATA

.x-personal-data {
  &-title {
    background: $brand-color-1;
    color: $light-text-color !important;
    font-family: $font-family-regular !important;
    font-size: 1.8rem !important;
    font-weight: 400 !important;
    line-height: 3.4rem !important;
    margin: 3rem 0 2rem 0 !important;
    padding: 0.3rem 2rem!important;
    text-align: center;
    text-transform: uppercase !important;
  }

  &-info {
    font-family: $font-family-regular !important;
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.6;
    margin: 0 0 1.4rem 0!important;
  }
}


// -----------------------------------------------------------------------------
// REGISTRATION: AGENDA ITEMS

.x-agenda {
  &-group-title {
    background: $brand-color-1;
    color: $light-text-color !important;
    font-family: $font-family-regular !important;
    font-size: 1.8rem !important;
    font-weight: 400 !important;
    margin: 2rem 0 1.6rem 0 !important;
    line-height: 3.4rem !important;
    padding: 0.3rem 2rem!important;
    text-align: center;
    text-transform: uppercase !important;
  }

  &-group-subtitle {
    background: $bg-color-1;
    color: $text-color !important;
    font-family: $font-family-regular !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    margin: 20px 0 16px 0 !important;
    line-height: 20px !important;
    padding: 7px 20px!important;
    text-align: center;
    text-transform: uppercase !important;
  }

  &-title {
    border: 0;
    border-top: 1px solid $dark-border-color;
    font-family: $font-family-regular !important;
    font-size: 16px !important;
    color: $brand-color-1;
    margin: 14px 0 7px 0 !important;
    padding: 6px 0 0 0;
  }

  &-description {
    font-family: $font-family-regular !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.6;
    margin: 0 0 14px 0!important;
  }

  &-attendance-limit {
    color: $okay-color;
    font-size: 14px !important;
  }

  &-attendance-booked-up {
    color: $warning-color;
  }

  .x-form-radio-wrapper {
    display: block;
  }
}

.x-captcha {
  .errorlist {
    color: $warning-color;
    list-style: none;
    padding: 0;
  }

  &-title {
    color: $brand-color-1 !important;
    font-family: $font-family-regular !important;
    font-size: 1.6rem !important;
    line-height: 1;
    margin: 1.4rem 0 0 0 !important;
  }
}

.x-ical {
  margin-bottom: 1rem;
}

.x-btn {
  border: 0.1rem solid $brand-color-1;
  color: $brand-color-1;
  display: inline-block;
  font-size: 1.4rem;
  margin-bottom: 1rem;
  padding: 0.6rem 1.2rem;
  text-align: center;
  user-select: none;
  white-space: nowrap;

  &:hover,
  &:focus {
    border-color: $text-color;
    color: $text-color;
  }
}

.x-event-export {
  align-items: center;
  display: inline-flex;
  font-size: 1.3rem;
  line-height: 1.3;
}

.x-google-map-wrapper {
  height: 0;
  margin-bottom: 4rem;
  padding-bottom: 60%;
  position: relative;
}

.x-google-map {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.x-escort-wrapper{
  margin-left: 2.3rem;
  margin-top: 1rem;
}

.x-escort-count-wrapper,
.x-agent-items-wrapper {
  margin-left: 2.3rem;
 }


// -----------------------------------------------------------------------------
// EVENTS

.x-headline-wrapper {
  align-items: center;
  display: flex;

  .x-link-event {
    margin-left: 2rem;
  }

  .x-admission-link {
    margin-left: auto;
  }

  .x-icon-link {
    display: block;
    fill: $brand-color-1;
    height: 2.4rem;
    width: 2.4rem;
    margin-left: 1.4rem;

    &:focus,
    &:hover {
      fill: $text-color;
    }

    &.x-first {
      margin-left: auto;
    }

    svg {
      height: 2.4rem;
      width: 2.4rem;
    }
  }
}


// -----------------------------------------------------------------------------
// EVENTS LIST

.x-events-list {
  tbody {
    td {
      vertical-align: middle !important;
    }
  }

  .x-actions {
    width: 13.2rem;
  }

  .x-actions-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  .x-icon-link {
    display: block;
    fill: $brand-color-1;
    height: 2rem;
    margin-left: 0.6rem;
    width: 2rem;

    &:focus,
    &:hover {
      fill: $text-color;
    }

    svg {
      height: 2rem;
      width: 2rem;
    }
  }

  .x-icon-delete {
    fill: $warning-color;
    padding-left: 0 !important;
  }
}
