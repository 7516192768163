//------------------------------------------------------------------------------
// ALLGEMEIN

.x-container {
  margin-left: auto;
  margin-right: auto;
  max-width: $container-max;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;

  @media (min-width: $breakpoint-sm) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: $breakpoint-lg) {
    padding-left: 60px;
    padding-right: 60px;
  }
}

.x-container-fluid {
  margin-left: auto;
  margin-right: auto;
  max-width: $breakpoint-xl;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;

  @media (min-width: $breakpoint-sm) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: $breakpoint-lg) {
    padding-left: 60px;
    padding-right: 60px;
  }
}


//------------------------------------------------------------------------------
// REIHE / SPALTEN

.x-row {
  display: flex;
  flex-wrap: wrap;
  margin-left: -15px;
  margin-right: -15px;

  &.x-align-items-center {
    align-items: center;
  }

  &.x-align-items-bottom {
    align-items: flex-end;
  }
}

.x-no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.x-no-gutters > .x-col,
.x-no-gutters > [class*="x-col-"] {
  padding-left: 0;
  padding-right: 0;
}

.x-col-1, .x-col-2, .x-col-3, .x-col-4, .x-col-5, .x-col-6, .x-col-7, .x-col-8,
.x-col-9, .x-col-10, .x-col-11, .x-col-12, .x-col, .x-col-auto, .x-col-sm-1,
.x-col-sm-2, .x-col-sm-3, .x-col-sm-4, .x-col-sm-5, .x-col-sm-6, .x-col-sm-7,
.x-col-sm-8, .x-col-sm-9, .x-col-sm-10, .x-col-sm-11, .x-col-sm-12, .x-col-sm,
.x-col-sm-auto, .x-col-md-1, .x-col-md-2, .x-col-md-3, .x-col-md-4, .x-col-md-5,
.x-col-md-6, .x-col-md-7, .x-col-md-8, .x-col-md-9, .x-col-md-10, .x-col-md-11,
.x-col-md-12, .x-col-md, .x-col-md-auto, .x-col-lg-1, .x-col-lg-2, .x-col-lg-3,
.x-col-lg-4, .x-col-lg-5, .x-col-lg-6, .x-col-lg-7, .x-col-lg-8, .x-col-lg-9,
.x-col-lg-10, .x-col-lg-11, .x-col-lg-12, .x-col-lg, .x-col-lg-auto,
.x-col-xl-1, .x-col-xl-2, .x-col-xl-3, .x-col-xl-4, .x-col-xl-5, .x-col-xl-6,
.x-col-xl-7, .x-col-xl-8, .x-col-xl-9, .x-col-xl-10, .x-col-xl-11, .x-col-xl-12,
.x-col-xl, .x-col-xl-auto {
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
  width: 100%;
}

.x-col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.x-col-auto {
  flex: 0 0 auto;
  max-width: none;
  width: auto;
}

.x-col-1 {
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.x-col-2 {
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.x-col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.x-col-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.x-col-5 {
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.x-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.x-col-7 {
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.x-col-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.x-col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.x-col-10 {
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.x-col-11 {
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.x-col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.x-order-first {
  order: -1;
}

.x-order-1 {
  order: 1;
}

.x-order-2 {
  order: 2;
}

.x-order-3 {
  order: 3;
}

.x-order-4 {
  order: 4;
}

.x-order-5 {
  order: 5;
}

.x-order-6 {
  order: 6;
}

.x-order-7 {
  order: 7;
}

.x-order-8 {
  order: 8;
}

.x-order-9 {
  order: 9;
}

.x-order-10 {
  order: 10;
}

.x-order-11 {
  order: 11;
}

.x-order-12 {
  order: 12;
}

.x-offset-1 {
  margin-left: 8.333333%;
}

.x-offset-2 {
  margin-left: 16.666667%;
}

.x-offset-3 {
  margin-left: 25%;
}

.x-offset-4 {
  margin-left: 33.333333%;
}

.x-offset-5 {
  margin-left: 41.666667%;
}

.x-offset-6 {
  margin-left: 50%;
}

.x-offset-7 {
  margin-left: 58.333333%;
}

.x-offset-8 {
  margin-left: 66.666667%;
}

.x-offset-9 {
  margin-left: 75%;
}

.x-offset-10 {
  margin-left: 83.333333%;
}

.x-offset-11 {
  margin-left: 91.666667%;
}


//------------------------------------------------------------------------------
//SPALTEN SMALL

@media (min-width: $breakpoint-sm) {
  .x-col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .x-col-sm-auto {
    flex: 0 0 auto;
    max-width: none;
    width: auto;
  }

  .x-col-sm-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .x-col-sm-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .x-col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .x-col-sm-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .x-col-sm-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .x-col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .x-col-sm-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .x-col-sm-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .x-col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .x-col-sm-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .x-col-sm-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .x-col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .x-order-sm-first {
    order: -1;
  }

  .x-order-sm-1 {
    order: 1;
  }

  .x-order-sm-2 {
    order: 2;
  }

  .x-order-sm-3 {
    order: 3;
  }

  .x-order-sm-4 {
    order: 4;
  }

  .x-order-sm-5 {
    order: 5;
  }

  .x-order-sm-6 {
    order: 6;
  }

  .x-order-sm-7 {
    order: 7;
  }

  .x-order-sm-8 {
    order: 8;
  }

  .x-order-sm-9 {
    order: 9;
  }

  .x-order-sm-10 {
    order: 10;
  }

  .x-order-sm-11 {
    order: 11;
  }

  .x-order-sm-12 {
    order: 12;
  }

  .x-offset-sm-0 {
    margin-left: 0;
  }

  .x-offset-sm-1 {
    margin-left: 8.333333%;
  }

  .x-offset-sm-2 {
    margin-left: 16.666667%;
  }

  .x-offset-sm-3 {
    margin-left: 25%;
  }

  .x-offset-sm-4 {
    margin-left: 33.333333%;
  }

  .x-offset-sm-5 {
    margin-left: 41.666667%;
  }

  .x-offset-sm-6 {
    margin-left: 50%;
  }

  .x-offset-sm-7 {
    margin-left: 58.333333%;
  }

  .x-offset-sm-8 {
    margin-left: 66.666667%;
  }

  .x-offset-sm-9 {
    margin-left: 75%;
  }

  .x-offset-sm-10 {
    margin-left: 83.333333%;
  }

  .x-offset-sm-11 {
    margin-left: 91.666667%;
  }
}


//------------------------------------------------------------------------------
//SPALTEN MEDIUM

@media (min-width: $breakpoint-md) {
  .x-col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .x-col-md-auto {
    flex: 0 0 auto;
    max-width: none;
    width: auto;
  }

  .x-col-md-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .x-col-md-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .x-col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .x-col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .x-col-md-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .x-col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .x-col-md-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .x-col-md-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .x-col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .x-col-md-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .x-col-md-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .x-col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .x-order-lg-first {
    order: -1;
  }

  .x-order-lg-1 {
    order: 1;
  }

  .x-order-lg-2 {
    order: 2;
  }

  .x-order-lg-3 {
    order: 3;
  }

  .x-order-lg-4 {
    order: 4;
  }

  .x-order-lg-5 {
    order: 5;
  }

  .x-order-lg-6 {
    order: 6;
  }

  .x-order-lg-7 {
    order: 7;
  }

  .x-order-lg-8 {
    order: 8;
  }

  .x-order-lg-9 {
    order: 9;
  }

  .x-order-lg-10 {
    order: 10;
  }

  .x-order-lg-11 {
    order: 11;
  }

  .x-order-lg-12 {
    order: 12;
  }

  .x-offset-lg-0 {
    margin-left: 0;
  }

  .x-offset-lg-1 {
    margin-left: 8.333333%;
  }

  .x-offset-lg-2 {
    margin-left: 16.666667%;
  }

  .x-offset-lg-3 {
    margin-left: 25%;
  }

  .x-offset-lg-4 {
    margin-left: 33.333333%;
  }

  .x-offset-lg-5 {
    margin-left: 41.666667%;
  }

  .x-offset-lg-6 {
    margin-left: 50%;
  }

  .x-offset-lg-7 {
    margin-left: 58.333333%;
  }

  .x-offset-lg-8 {
    margin-left: 66.666667%;
  }

  .x-offset-lg-9 {
    margin-left: 75%;
  }

  .x-offset-lg-10 {
    margin-left: 83.333333%;
  }

  .x-offset-lg-11 {
    margin-left: 91.666667%;
  }
}


//------------------------------------------------------------------------------
//SPALTEN LARGE

@media (min-width: $breakpoint-lg) {
  .x-col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .x-col-lg-auto {
    flex: 0 0 auto;
    max-width: none;
    width: auto;
  }

  .x-col-lg-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .x-col-lg-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .x-col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .x-col-lg-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .x-col-lg-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .x-col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .x-col-lg-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .x-col-lg-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .x-col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .x-col-lg-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .x-col-lg-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .x-col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .x-order-lg-first {
    order: -1;
  }

  .x-order-lg-1 {
    order: 1;
  }

  .x-order-lg-2 {
    order: 2;
  }

  .x-order-lg-3 {
    order: 3;
  }

  .x-order-lg-4 {
    order: 4;
  }

  .x-order-lg-5 {
    order: 5;
  }

  .x-order-lg-6 {
    order: 6;
  }

  .x-order-lg-7 {
    order: 7;
  }

  .x-order-lg-8 {
    order: 8;
  }

  .x-order-lg-9 {
    order: 9;
  }

  .x-order-lg-10 {
    order: 10;
  }

  .x-order-lg-11 {
    order: 11;
  }

  .x-order-lg-12 {
    order: 12;
  }

  .x-offset-lg-0 {
    margin-left: 0;
  }

  .x-offset-lg-1 {
    margin-left: 8.333333%;
  }

  .x-offset-lg-2 {
    margin-left: 16.666667%;
  }

  .x-offset-lg-3 {
    margin-left: 25%;
  }

  .x-offset-lg-4 {
    margin-left: 33.333333%;
  }

  .x-offset-lg-5 {
    margin-left: 41.666667%;
  }

  .x-offset-lg-6 {
    margin-left: 50%;
  }

  .x-offset-lg-7 {
    margin-left: 58.333333%;
  }

  .x-offset-lg-8 {
    margin-left: 66.666667%;
  }

  .x-offset-lg-9 {
    margin-left: 75%;
  }

  .x-offset-lg-10 {
    margin-left: 83.333333%;
  }

  .x-offset-lg-11 {
    margin-left: 91.666667%;
  }
}


//------------------------------------------------------------------------------
//SPALTEN XLARGE

@media (min-width: $breakpoint-xl) {
  .x-col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }

  .x-col-xl-auto {
    flex: 0 0 auto;
    max-width: none;
    width: auto;
  }

  .x-col-xl-1 {
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .x-col-xl-2 {
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .x-col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .x-col-xl-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .x-col-xl-5 {
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .x-col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .x-col-xl-7 {
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .x-col-xl-8 {
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .x-col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .x-col-xl-10 {
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .x-col-xl-11 {
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .x-col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .x-order-lg-first {
    order: -1;
  }

  .x-order-lg-1 {
    order: 1;
  }

  .x-order-lg-2 {
    order: 2;
  }

  .x-order-lg-3 {
    order: 3;
  }

  .x-order-lg-4 {
    order: 4;
  }

  .x-order-lg-5 {
    order: 5;
  }

  .x-order-lg-6 {
    order: 6;
  }

  .x-order-lg-7 {
    order: 7;
  }

  .x-order-lg-8 {
    order: 8;
  }

  .x-order-lg-9 {
    order: 9;
  }

  .x-order-lg-10 {
    order: 10;
  }

  .x-order-lg-11 {
    order: 11;
  }

  .x-order-lg-12 {
    order: 12;
  }

  .x-offset-lg-0 {
    margin-left: 0;
  }

  .x-offset-lg-1 {
    margin-left: 8.333333%;
  }

  .x-offset-lg-2 {
    margin-left: 16.666667%;
  }

  .x-offset-lg-3 {
    margin-left: 25%;
  }

  .x-offset-lg-4 {
    margin-left: 33.333333%;
  }

  .x-offset-lg-5 {
    margin-left: 41.666667%;
  }

  .x-offset-lg-6 {
    margin-left: 50%;
  }

  .x-offset-lg-7 {
    margin-left: 58.333333%;
  }

  .x-offset-lg-8 {
    margin-left: 66.666667%;
  }

  .x-offset-lg-9 {
    margin-left: 75%;
  }

  .x-offset-lg-10 {
    margin-left: 83.333333%;
  }

  .x-offset-lg-11 {
    margin-left: 91.666667%;
  }
}

